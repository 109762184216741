/** *******************************************************************************************************************
  Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

  Licensed under the Apache License, Version 2.0 (the "License").
  You may not use this file except in compliance with the License.
  You may obtain a copy of the License at

      http://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
 ******************************************************************************************************************** */
import { z } from 'zod';
import { ContentEntityBaseSchema, EntityLinkBaseSchema } from './entities';

export const AssumptionSchema = ContentEntityBaseSchema.extend({
  /**
   * A flag determining the assumption is still valid or not.
   */
  valid: z.boolean().optional(),
}).strict();

export type Assumption = z.infer<typeof AssumptionSchema>;

export const AssumptionLinkSchema = EntityLinkBaseSchema.extend({
  type: z.union([z.literal('Mitigation'), z.literal('Threat')]),
  /**
   * The assumption being linked.
   */
  assumptionId: z.string().length(36),
  /**
   * The linked entity Id.
   */
  linkedId: z.string().length(36),
}).strict();

export type AssumptionLink = z.infer<typeof AssumptionLinkSchema>;

